import { Navbar as Nav } from "@dolthub/react-components";
import { DeploymentParams } from "@lib/params";
import {
  DesktopRightLinks,
  ExitWorkbenchLink,
  LeftLinks,
  LogoLink,
  MobileRightLinks,
  WorkbenchLogoLink,
} from "./Links";

// Used in SignedOutLayout
export default function Navbar() {
  return (
    <Nav
      bgColor="bg-ocean-400"
      logo={<LogoLink />}
      leftLinks={<LeftLinks />}
      rightLinks={<DesktopRightLinks />}
      rightLinksMobile={<MobileRightLinks />}
      large
    />
  );
}

type DBProps = {
  params: DeploymentParams;
};

// Used in DatabaseLayout
function ForDatabase(props: DBProps) {
  return (
    <Nav
      bgColor="bg-ocean-500"
      logo={<WorkbenchLogoLink />}
      leftLinks={<LeftLinks hidePricing />}
      rightLinks={<ExitWorkbenchLink params={props.params} />}
      rightLinksMobile={<MobileRightLinks />}
    />
  );
}

Navbar.ForDatabase = ForDatabase;
