import Link from "@components/links/Link";
import css from "./index.module.css";

function CopyRight() {
  return (
    <div className={css.copyright}>
      © {new Date().getFullYear()} DoltHub, Inc. All rights reserved.
    </div>
  );
}

export default function Footer() {
  return (
    <div className={css.container} data-cy="footer">
      <div className={css.bottom}>
        <div className={css.links}>
          <Link href="/security" data-cy="footer-security">
            Security
          </Link>
          <Link href="/privacy-policy" data-cy="footer-privacy">
            Privacy
          </Link>
          <Link href="/terms" data-cy="footer-terms">
            Terms
          </Link>
        </div>
        <CopyRight />
      </div>
    </div>
  );
}
