import { Button } from "@dolthub/react-components";
import { GoPrimitiveDot } from "@react-icons/all-files/go/GoPrimitiveDot";
import cx from "classnames";
import css from "./index.module.css";

type ItemProps = {
  text: string;
};

type DetailsProps = {
  title: string;
  detail: string;
  rightPadding: boolean;
};

type StepProps = {
  num: number;
} & ItemProps;

export function ListItems(props: ItemProps) {
  return (
    <li className={css.item}>
      <GoPrimitiveDot />
      <p>{props.text}</p>
    </li>
  );
}

export function ListSteps(props: StepProps) {
  return (
    <li className={css.step}>
      <Button>{props.num}</Button>
      <p>{props.text}</p>
    </li>
  );
}

export function ListDetails(props: DetailsProps) {
  return (
    <li className={cx(css.item, css.svgSize)}>
      <GoPrimitiveDot />
      <div className={css.container}>
        <div
          className={cx(css.title, { [css.rightPadding]: props.rightPadding })}
        >
          {props.title}
        </div>
        <div className={css.detail}>{props.detail}</div>
      </div>
    </li>
  );
}
