import Footer from "@components/Footer";
import Navbar from "@components/Navbar";
import cx from "classnames";
import { ReactNode } from "react";
import css from "./index.module.css";

type Props = {
  children: ReactNode;
  hideClouds?: boolean;
  innerContainerClassName?: string;
};

export default function SignedOutLayout(props: Props) {
  return (
    <div className={css.container}>
      <Navbar />
      <div className={css.content}>
        <div className={css.main} id="main-content">
          <div
            className={cx(
              { [css.withClouds]: !props.hideClouds },
              props.innerContainerClassName,
            )}
          >
            {props.children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
