import DocsLink from "@components/links/DocsLink";
import Link from "@components/links/Link";
import { Btn, ExternalLink, Loader } from "@dolthub/react-components";
import useCurrentUser from "@hooks/useCurrentUser";
import { discordLink, doltGithubRepo } from "@lib/constants";
import { DeploymentParams } from "@lib/params";
import { deployment } from "@lib/urls";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";
import { FaDiscord } from "@react-icons/all-files/fa/FaDiscord";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { DesktopAuthedLinks, MobileAuthedLinks } from "./AuthedLinks";
import css from "./index.module.css";

// LEFT LINKS

function DeploymentsLink() {
  const { currentUser, loading, error } = useCurrentUser();
  if (loading) return <Loader loaded={false} />;
  if (!currentUser || error) return null;

  return (
    <Link href="/deployments" data-cy="navbar-deployments">
      Deployments
    </Link>
  );
}

export function LeftLinks(props: { hidePricing?: boolean }) {
  return (
    <>
      <DeploymentsLink />
      {!props.hidePricing && (
        <Link href="/pricing" data-cy="navbar-pricing">
          Pricing
        </Link>
      )}
      <DocsLink data-cy="navbar-documentation" path="/products/hosted">
        Documentation
      </DocsLink>
    </>
  );
}

// LOGOS

export function LogoLink() {
  return (
    <Link href="/" data-cy="navbar-logo">
      <img src="/images/Hosted-Dolt-Logo.svg" alt="Hosted Dolt" />
    </Link>
  );
}

export function WorkbenchLogoLink() {
  return (
    <Link href="/" data-cy="navbar-workbench-logo">
      <img
        src="/images/hosted-workbench-logo.png"
        alt="Hosted Dolt Workbench"
      />
    </Link>
  );
}

// RIGHT LINKS

function SocialLinks() {
  return (
    <>
      <ExternalLink href={discordLink} data-cy="navbar-discord">
        <FaDiscord />
        Discord
      </ExternalLink>
      <ExternalLink href={doltGithubRepo} data-cy="navbar-github">
        <FaGithub />
        GitHub
      </ExternalLink>
    </>
  );
}

// DESKTOP RIGHT LINKS

export function DesktopRightLinks() {
  return (
    <>
      <SocialLinks />
      <DesktopAuthedLinks />
    </>
  );
}

type DepProps = {
  params: DeploymentParams;
};

export function ExitWorkbenchLink(props: DepProps) {
  return (
    <Link
      {...deployment({
        ownerName: props.params.ownerName,
        deploymentName: props.params.deploymentName,
      })}
      className={css.exit}
    >
      <Btn data-cy="database-exit-button">
        Exit
        <span className={css.closeIcon}>
          <AiOutlineClose />
        </span>
      </Btn>
    </Link>
  );
}

// MOBILE RIGHT LINKS

export function MobileRightLinks() {
  return (
    <>
      <SocialLinks />
      <MobileAuthedLinks />
    </>
  );
}
