import SignOutButton from "@components/SignOutButton";
import Link from "@components/links/Link";
import { Button, Loader } from "@dolthub/react-components";
import useCurrentUser from "@hooks/useCurrentUser";
import NavbarMenu from "../NavbarMenu";
import css from "./index.module.css";

function SettingsLink() {
  return (
    <Link href="/settings" data-cy="navbar-settings">
      Settings
    </Link>
  );
}

function SigninLink() {
  return (
    <Link href="/signin" data-cy="navbar-signin">
      <Button.Outlined className={css.signinButton} pill>
        Sign in
      </Button.Outlined>
    </Link>
  );
}

export function DesktopAuthedLinks() {
  const { currentUser, loading, error } = useCurrentUser();
  if (loading) return <Loader loaded={false} />;
  if (!currentUser || error) {
    return <SigninLink />;
  }
  return <NavbarMenu user={currentUser} />;
}

export function MobileAuthedLinks() {
  const { currentUser, loading, error } = useCurrentUser();
  if (loading) return <Loader loaded={false} />;
  if (!currentUser || error) return <SigninLink />;
  return (
    <>
      <SettingsLink />
      <SignOutButton deviceDataCy="mobile" pill />
    </>
  );
}
