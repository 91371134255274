export const hostedGithubRepo =
  "https://github.com/dolthub/hosted-issues/issues";
export const hostedNewIssue = `${hostedGithubRepo}/new/choose`;
export const discordLink = "https://discord.gg/gqr7K4VNKe";
export const docsLink = "https://docs.dolthub.com";
export const doltGithubRepo = "https://github.com/dolthub/dolt";
export const doltgresGithubRepo = "https://github.com/dolthub/doltgresql";
export const dolthubLink = "https://www.dolthub.com";
export const doltlabLink = "http://doltlab.dolthub.com";
export const hostedProd = "https://hosted.doltdb.com";
export const hostedDev = "https://hosteddoltdb.hosteddev.ld-corp.com";
export const localhostUrl = "http://localhost:3001";
export const hostedVideoLink =
  "https://www.dolthub.com/blog/2022-05-20-hosted-dolt-howto/";
export const teamLink = "https://www.dolthub.com/team";
export const mariaDBLink = "https://mariadb.com/products/skysql/";
export const awsRDSLink = "https://aws.amazon.com/rds/";
