import SignOutButton from "@components/SignOutButton";
import Link from "@components/links/Link";
import { Btn } from "@dolthub/react-components";
import { useOnClickOutside } from "@dolthub/react-hooks";
import { CurrentUserFragment } from "@gen/graphql-types";
import { AiOutlineDown } from "@react-icons/all-files/ai/AiOutlineDown";
import { AiOutlineUp } from "@react-icons/all-files/ai/AiOutlineUp";
import { useRef, useState } from "react";
import css from "./index.module.css";

type Props = {
  user: CurrentUserFragment;
};

export default function NavbarMenu(props: Props) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setOpen(false));

  return (
    <div ref={dropdownRef}>
      <Btn
        className={css.openButton}
        onClick={() => setOpen(!open)}
        data-cy="navbar-menu-name"
      >
        <div>{props.user.displayName}</div>
        {open ? <AiOutlineUp /> : <AiOutlineDown />}
      </Btn>
      {open && (
        <div className={css.dropdown}>
          <div>
            <Link href="/support" data-cy="navbar-menu-support">
              Support
            </Link>
          </div>
          <div>
            <Link href="/contact" data-cy="navbar-menu-contact">
              Contact DoltHub
            </Link>
          </div>
          <div>
            <Link href="/settings" data-cy="navbar-menu-settings">
              Settings
            </Link>
          </div>
          <div>
            <SignOutButton deviceDataCy="desktop" />
          </div>
        </div>
      )}
    </div>
  );
}
